import React from 'react';


import WeddingGallery from './WeddingGallery';
import { NavLink } from 'react-router-dom';

import './Weddings.css';

const alexMihanImages = require.context('../../../assets/images/weddings/alexmihan', true);
const alexMihanImageList = alexMihanImages.keys().map(image => alexMihanImages(image));

const joeAndBethImages = require.context('../../../assets/images/weddings/joe_and_beth', true);
const joeAndBethImageList = joeAndBethImages.keys().map(image => joeAndBethImages(image));

const melanieAndDanielImages = require.context('../../../assets/images/weddings/melanie_and_daniel', true);
const melanieAndDanielImageList = melanieAndDanielImages.keys().map(image => melanieAndDanielImages(image));

const ginAndJacImages = require.context('../../../assets/images/weddings/gin_and_jac', true);
const ginAndJacImageList = ginAndJacImages.keys().map(image => ginAndJacImages(image));

const amandaAndBryanImages = require.context('../../../assets/images/weddings/amanda_and_bryan', true);
const amandaAndBryanImageList = amandaAndBryanImages.keys().map(image => amandaAndBryanImages(image));



const WeddingsLanding = () => {
  return (
    <section className='weddings-div'>

      <div className='weddings-content-div'>      
        <div >
          <p>
            We at Northern Blush Floral aspire to form harmonious relationships,
            that allow us to arrange truly what you've envisioned and have been
            inspired by to create your ideal florals for any special occasion.
          </p>
          <p>
            <NavLink to='/weddings'>
              <button className='btn service-button-btn'>Make a Wedding Inquiry</button>
            </NavLink>
          </p>

        </div>
        <WeddingGallery title="Joe + Beth" images={joeAndBethImageList} photographer_credit={"@loriwaltenburyphoto"}/>
        <WeddingGallery title="Alex + Kyle" images={alexMihanImageList} photographer_credit={"@whimandwillow"}/>
        <WeddingGallery title="Melanie + Daniel" images={melanieAndDanielImageList} photographer_credit={"@taylorannephotographs"}/>
        <WeddingGallery title="Gin + Jac" images={ginAndJacImageList} photographer_credit={"@taylorannephotographs"}/>
        <WeddingGallery title="Amanda + Bryan" images={amandaAndBryanImageList} photographer_credit={"@abalistphoto"}/>
      </div>

    </section>
  );
};

export default WeddingsLanding;
