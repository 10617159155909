import React from 'react';


import './WeddingGallery.css';


const WeddingGallery = (props) => (

    <div class="wedding-section">
    <h3 class="wedding-title">{props.title}</h3>
    <p class="wedding-title">Photography by {props.photographer_credit}</p>
    <div class="wedding-gallery">
	 {props.images.map(image => (
        <div class="wedding-img-container">
          <img class="wedding-img" src={image}/>
        </div>
    ))
	}
  </div>
	</div>


);

export default WeddingGallery;





