import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  withRouter,
} from 'react-router-dom';

// Components
import MainNavigation from './shared/components/Navigation/MainNavigation';
import Home from './pages/components/Home';
import About from './pages/components/About';
import WeddingsInquiry from './pages/components/Weddings/WeddingInquiry';
import Weddings from './pages/components/Weddings/Weddings';

import Order from './pages/components/Order';
import Footer from './shared/components/Navigation/Footer';
import CovidDialog from './shared/components/UIElements/CovidDialog';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const App = () => {
  return (
    <>
      <Router>
        <ScrollToTop>
          <CovidDialog />
          <MainNavigation />
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/about'>
              <About />
            </Route>
            <Route path='/weddings'>
              <WeddingsInquiry />
            </Route>
            <Route path='/order'>
              <Order />
            </Route>
            <Route path='/weddings_landing'>
              <Weddings />
            </Route>
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </>
  );
};

export default App;
